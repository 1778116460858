const ContactUs = () => {
    return (
        <>
            <section className="wpo-breadcumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="wpo-breadcumb-wrap">
                                <h2>UP 电器</h2>
                                <h3>Contact Us</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="wpo-contact-page section-padding">
                <div className="container">
                    <div className="office-info">
                        <div className="row">
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div className="office-info-item">
                                    <div className="office-info-icon">
                                        <div className="icon">
                                            <i className="fi flaticon-location-1"></i>
                                        </div>
                                    </div>
                                    <div className="office-info-text">
                                        <h2>服务地址</h2>
                                        <p>肖南路368号1幢3层
                                            <div>
                                            奉贤,上海，中国
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div className="office-info-item active">
                                    <div className="office-info-icon">
                                        <div className="icon">
                                            <i className="fi flaticon-phone-call"></i>
                                        </div>
                                    </div>
                                    <div className="office-info-text">
                                        <h2>Phone Number</h2>
                                        <p>13761347046 </p>
                                        <p>021-67151140</p>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="col col-lg-4 col-md-6 col-12">
                                <div className="office-info-item">
                                    <div className="office-info-icon">
                                        <div className="icon">
                                            <i className="fi flaticon-email"></i>
                                        </div>
                                    </div>
                                    <div className="office-info-text">
                                        <h2>Email</h2>
                                        <p>shengdalion@126.com</p>
                                        <p>-</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contact-wrap">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="contact-left">
                                    <h2>联系我们</h2>
                                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit mattis
                                        faucibus odio feugiat arc dolor.</p>
                                    <img className="image" src="assets/images/contact-left-img.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="contact-right">
                                    <div className="title">
                                        <h2>填写留言</h2>
                                        <p>Your email address will not be published. Required fields are marked *</p>
                                    </div>
                                    <form id="contactForm" className="contact-form">
                                        <div className="input-item">
                                            <input id="name" className="fild" type="text" placeholder="名字*" required />
                                            <label><i className="flaticon-user"></i></label>
                                        </div>
                                        <div className="input-item">
                                            <input id="email" className="fild" type="email" placeholder="邮箱*"
                                                required />
                                            <label><i className="flaticon-email"></i></label>
                                        </div>
                                        <div className="input-item">
                                            <textarea id="message" className="fild textarea"
                                                placeholder="您的宝贵留言" required></textarea>
                                            <label><i className="flaticon-edit"></i></label>
                                        </div>
                                        <div className="input-item submitbtn">
                                            <input className="fild" type="submit" value="发送留言" />
                                            <label><i className="flaticon-send"></i></label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default ContactUs;

