import { useParams } from "react-router";
import ProductLeftNav from "./LeftNav";
import ProductMain from "./Main";
import './style.css'
import { useEffect, useState } from "react";

const ProductsComponent = () => {
    const {categoryName: initialCategory} = useParams();
    const [categoryName, setCategoryName] = useState(initialCategory);
    return (
        <div className="container">
            <div className="row top-slider" >
                <div className="col-lg-9 col-12 order-lg-2">
                    <div className="service-single-wrap">
                        <ProductMain category={categoryName}/>
                    </div>
                </div>

                <div className="col-lg-3 col-12 order-lg-1">
                    <ProductLeftNav category={categoryName} onCategoryChange={setCategoryName}/>
                </div>
            </div>
        </div>
    );
};

export default ProductsComponent;