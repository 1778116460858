const ProductDetails = ({ name, price, category, description }) => {
    return (<div className="product-details">
        <h2>{name}</h2>
        <div className="product-rt">
            <div className="rating">
                <i className="flaticon-star"></i>
                <i className="flaticon-star"></i>
                <i className="flaticon-star"></i>
                <i className="flaticon-star"></i>
                <i className="ti-star"></i>
            </div>
            <span>(25个用户评价了该商品)</span>
        </div>
        {/* <div className="price">
            <span className="current">¥ {price}</span>
        </div> */}
        <p>{description}</p>
        {/* <ul>
            <li>Going through the cites of the word in classical.</li>
            <li>There are many variations of passages.</li>
            <li>Making it look like readable and spoken English.</li>
        </ul> */}
        <div className="tg-btm">
            <p><span>类别:</span> {category}</p>
        </div>
    </div>);
};

export default ProductDetails;