
const Examples = ()=>{

    const services = [
        {
            title: '基础设备与通信交通',
            img: '轨道交通.jpg',
            desc: 'UP电器对全球基础设备与能信交通所需要的PTC风扇加热器提供解决'
        },
        {
            title: '充电桩除湿',
            img: '充电桩.jpg',
            desc: 'UP电器充电桩解决方案'
        },
        {
            title: '多领域应用',
            img: '多功能.jpg',
            desc: 'UP电器多领域应用解决方案'
        },
        {
            title: '自动售货机加热应用',
            img: '自动售货机.jpg',
            desc: 'UP电器自动售货机加热应用解决方案'
        },
        {
            title: '户外机柜加热应用',
            img: '充电器.jpg',
            desc: 'UP电器户外机柜加热应用解决方案'
        },
        {
            title: '能源',
            img: '新能源.jpg',
            desc: 'UP电器户外机柜加热应用解决方案'
        }

        
    ];

    return (
        <section className="wpo-blog-section section-padding">
        <div className="bg-img">
            <img src="assets/images/blog/bg-img.png" alt="" />
        </div>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6 col-12">
                    <div className="wpo-section-title">
                        <h2>UP电器</h2>
                        <h3>Our Applications are all over the world</h3>
                    </div>
                </div>
            </div>
            <div className="row">
              {
                services.map((_, index)=>
                    <div className="col-lg-4 col-md-6 col-12">
                    <div className="blog-card">
                        <img src={`assets/images/service/${_.img}`} alt="" />
                        <div className="content">
                            <div className="date">
                                <h3>0{index+1}</h3>
                                <span><i className="flaticon-right-arrow-1"></i></span>
                            </div>
                            <div className="text">
                                <span>UP服务</span>
                                <h2><a>{_.title}</a></h2>
                            </div>
                            {/* <ul className="comment">
                                <li><i className="flaticon-info"></i> <span>{_.desc}</span></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                )
              }
            
            </div>
        </div>
    </section>
    );
};

export default Examples;