import { useCategories } from "Provider/CategoryProvider";
import AdsComponent from "../Common/Ads/Ads";
import { useParams } from "react-router";
import { useEffect, useState } from "react";

const ProductLeftNav = ({category, onCategoryChange}) => {
    const [active, setActive] = useState([category]);

    const toggleCategory = (name) => {
        if (active == name) return;
        setActive(name);
        onCategoryChange(name);
    }
    const { categories } = useCategories();
    return (<>
        <div className="service-sidebar">
            <div className="service-catagory">
                <ul>
                    {
                        categories && categories.length > 0 && categories.filter(_ => _.parent !== null && _.parent !== "").slice(0, 10).map(
                            v => <li><a onClick={() => toggleCategory(v.name)} className={active == v.name ? 'active' : ''}>{v.name}<i
                                className="flaticon-right-arrow-1"></i></a></li>
                        )
                    }


                </ul>
            </div>
            <AdsComponent />
        </div>
    </>);
};

export default ProductLeftNav;